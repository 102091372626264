@import "@/styles/_variables.scss";





































.round-img-wrapper  {
  min-height: 40px;
  min-width: 40px;
  img {
    border-radius: 50%;
    object-fit: cover;
  }  
}
